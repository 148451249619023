import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import InfoIcon from "@mui/icons-material/Info";
import { isMobile } from "react-device-detect";
import { cssTransition, toast, ToastPosition } from "react-toastify";
import { v4 as uuidv4 } from "uuid";

import ToastPaper from "../components/custom/ToastPaper";

const bounce = cssTransition({
  enter: "animate__animated animate__bounceIn",
  exit: "animate__animated animate__bounceOut",
  appendPosition: false,
  collapse: true,
});

const getToastStyle = (position?: ToastPosition) => {
  const baseStyle = {
    fontSize: isMobile ? 10 : 12,
    height: isMobile ? 50 : null,
  };

  if (position === "top-center") {
    return {
      ...baseStyle,
      fontWeight: "bold",
      justifyContent: "center",
      display: "flex",
      top: 365,
      padding: 0,
    };
  }

  return baseStyle;
};

type NotifyOptions = {
  position?: ToastPosition;
  theme?: "light" | "dark" | "colored";
  time?: number;
  style?: { [key: string]: string };
  modal?: boolean;
  result?: any;
  value?: string;
};

const notify = (
  message: string,
  type: "success" | "error",
  options?: NotifyOptions
) => {
  if (options?.modal) {
    const defaultProgressStyle =
      type === "success"
        ? { backgroundColor: "#08C58A" }
        : { backgroundColor: "#FF025C" };

    const progressStyle = { ...defaultProgressStyle, ...options?.style };

    const toastOptions = {
      style: getToastStyle(options?.position),
      theme: options?.theme || "dark",
      closeButton: false,
      position: "top-center" as ToastPosition,
      progressStyle,
      hideProgressBar: true,
      pauseOnFocusLoss: false,
      pauseOnHover: false,
      autoClose: 15000,
      draggable: false,
      transition: bounce,
      className: options?.modal ? "Toastify__toast--modal" : "", // Adicionar a classe para modais
    };

    const uniqueToastId = uuidv4(); // Gerar um ID único para cada toast
    return toast(
      <ToastPaper result={options?.result} value={options?.value} />,
      { ...toastOptions, toastId: uniqueToastId }
    );
  } else {
    const defaultStyle = {
      backgroundColor: "#1a1a1a",
      color: "white",
      fontSize: 14,
      height: isMobile ? 50 : null,
    };

    const progressStyle = {
      backgroundColor: type === "success" ? "#08C58A" : "#FF025C",
    };

    const toastOptions = {
      closeButton: true,
      style: defaultStyle,
      position: "bottom-right" as ToastPosition,
      progressStyle,
      pauseOnFocusLoss: false,
      pauseOnHover: false,
      autoClose: 15000,
    };

    if (type === "success") {
      toast.success(message, {
        ...toastOptions,
        icon: ({ theme, type }) => (
          <CheckCircleIcon sx={{ color: "#08C58A" }} />
        ),
      });
    } else {
      toast.error(message, {
        ...toastOptions,
        icon: ({ theme, type }) => <InfoIcon sx={{ color: "#FF025C" }} />,
      });
    }
  }
};

export const notifySuccess = (message: string, options?: NotifyOptions) => {
  notify(message, "success", options);
};

export const notifyError = (message: string, options?: NotifyOptions) => {
  notify(message, "error", options);
};
