import {
  Box,
  FormControl,
  Skeleton,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaCircleMinus, FaCirclePlus } from "react-icons/fa6";
import { ResolutionString } from "src/charting_library/charting_library";
import { InputAdornmentIcon } from "src/components/InputAdornmentIcon";
import TimePicker from "src/components/shared/TimePicker";
import Countdown from "src/components/widgets/inputs/Countdown";
import CountdonwContext from "src/contexts/CountdownContext";
import TradingViewContext from "src/contexts/TradingViewContext";
import useParameters from "src/swr/use-parameters";
import useApiData from "../../../hooks/useApiData";

const SYMBOL_IMAGES = {
  BTCUSDT: {
    symbol: "BTC/USDT",
    image:
      "https://ebinex-public.s3.sa-east-1.amazonaws.com/simbols/BTCUSDT.png",
  },
  ETHUSDT: {
    symbol: "ETH/USDT",
    image:
      "https://ebinex-public.s3.sa-east-1.amazonaws.com/simbols/ETHUSDT.png",
  },
  SOLUSDT: {
    symbol: "SOL/USDT",
    image:
      "https://ebinex-public.s3.sa-east-1.amazonaws.com/simbols/SOLUSDT.png",
  },
  XRPUSDT: {
    symbol: "XRP/USDT",
    image:
      "https://ebinex-public.s3.sa-east-1.amazonaws.com/simbols/XRPUSDT.png",
  },
  ADAUSDT: {
    symbol: "ADA/USDT",
    image:
      "https://ebinex-public.s3.sa-east-1.amazonaws.com/simbols/ADAUSDT.png",
  },
  EURUSD: {
    symbol: "EUR/USD",
    image:
      "https://ebinex-public.s3.sa-east-1.amazonaws.com/simbols/EURUSD.png",
  },
  GBPUSD: {
    symbol: "GBP/USD",
    image:
      "https://ebinex-public.s3.sa-east-1.amazonaws.com/simbols/GBPUSD.png",
  },
  USDJPY: {
    symbol: "USD/JPY",
    image:
      "https://ebinex-public.s3.sa-east-1.amazonaws.com/simbols/USDJPY.png",
  },
  USDCHF: {
    symbol: "USD/CHF",
    image:
      "https://ebinex-public.s3.sa-east-1.amazonaws.com/simbols/USDCHF.png",
  },
  IDXUSDT: {
    symbol: "IDX/USDT",
    image: "https://ebinex-public.s3.sa-east-1.amazonaws.com/simbols/IDX.png",
  },
  MEMXUSDT: {
    symbol: "MEMX/USDT",
    image:
      "https://ebinex-public.s3.sa-east-1.amazonaws.com/simbols/MEMXUSDT.png",
  },
  TRUMPUSDT: {
    symbol: "TRUMP/USDT",
    image:
      "https://ebinex-public.s3.sa-east-1.amazonaws.com/simbols/TRUMPUSDT.png",
  },
};

export default function TimeframeSelect() {
  const { t } = useTranslation("dashboard");
  const { countValue, setSelectedTimeFrame } = useContext(CountdonwContext);
  const [candleBuckets, setCandleBuckets] = useState(null);
  const [activeMinute, setActiveMinute] = useState(null);
  const [minutes, setMinutes] = useState<number>(1);

  const { parameters } = useParameters();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { tvWidget, selectedSymbol, removeOrdersByTimeframe } =
    React.useContext(TradingViewContext);
  const { selectedCandle, updateTimeframe, operationMode } = useApiData();

  useEffect(() => {
    if (parameters) {
      const candleTimeframes = JSON.parse(parameters?.CANDLE_TIME_FRAMES.value);
      setActiveMinute(candleTimeframes[0]);
      setSelectedTimeFrame(candleTimeframes[0]);
      setCandleBuckets(candleTimeframes);
    }
  }, [parameters]);

  const handleCandleButtonClick = async (e, item) => {
    const candleBucketsFind = candleBuckets.find((c) => c.value === item);
    if (candleBucketsFind) {
      if (tvWidget) {
        tvWidget
          .activeChart()
          .setResolution(
            String(candleBucketsFind.valueChart) as ResolutionString
          );
      }

      await updateTimeframe(item);
    }
  };

  useEffect(() => {
    let defaultCandleTimeFrame = localStorage.getItem("defaultCandleTimeFrame");

    if (defaultCandleTimeFrame) {
      defaultCandleTimeFrame = defaultCandleTimeFrame
        .replace(/\\/g, "")
        .replace(/"/g, "");
    } else {
      defaultCandleTimeFrame = "M1"; // Valor padrão
    }

    if (defaultCandleTimeFrame && candleBuckets) {
      const activeTimeframe = candleBuckets.find(
        (c) => c.value === defaultCandleTimeFrame
      );
      setActiveMinute(activeTimeframe);
      setSelectedTimeFrame(activeTimeframe);
      updateTimeframe(defaultCandleTimeFrame);
    }
  }, [candleBuckets]);

  useEffect(() => {
    if (
      operationMode === "OPTION" &&
      candleBuckets &&
      candleBuckets.length > 0
    ) {
      localStorage.setItem("subscribeTimeframeStatus", "LOADING");
      const newMinute = candleBuckets[0];
      removeOrdersByTimeframe(newMinute.value);
      setActiveMinute(newMinute);
      setSelectedTimeFrame(newMinute);
      handleCandleButtonClick("", newMinute.value);
    }
  }, [operationMode, candleBuckets]);

  useEffect(() => {
    if (
      selectedSymbol === "BNBUSDT" &&
      selectedCandle === "M1" &&
      candleBuckets
    ) {
      increaseAmount();
    }
  }, [selectedSymbol]);

  const increaseAmount = () => {
    const minuteIndex = candleBuckets.findIndex(
      (m) => m.value === activeMinute.value
    );
    if (!candleBuckets[minuteIndex + 1]) return;

    localStorage.setItem("subscribeTimeframeStatus", "LOADING");
    const newMinute = candleBuckets[minuteIndex + 1];
    removeOrdersByTimeframe(newMinute.value);
    setActiveMinute(newMinute);
    setSelectedTimeFrame(newMinute);
    handleCandleButtonClick("", newMinute.value);
  };

  const decreaseAmount = () => {
    if (selectedSymbol === "BNBUSDT" && activeMinute.value === "M5") return;
    if (!activeMinute || activeMinute.value === "M1") return;
    const minuteIndex = candleBuckets.findIndex(
      (m) => m.value === activeMinute.value
    );

    console.log("minuteIndex", minuteIndex);
    localStorage.setItem("subscribeTimeframeStatus", "LOADING");
    const newMinute = candleBuckets[minuteIndex - 1];
    removeOrdersByTimeframe(newMinute.value);
    setActiveMinute(newMinute);
    setSelectedTimeFrame(newMinute);
    handleCandleButtonClick("", newMinute.value);
  };

  if (!candleBuckets) {
    <Skeleton variant="rounded" width={"100%"} height={50} />;
  }

  const inputStyle = {
    "& .Mui-disabled": {
      textAlign: "center",
      color: "#CCC",
      WebkitTextFillColor: "#CCC !important",
    },
    "& .MuiFormLabel-root": {
      textAlign: "center",
      WebkitTextFillColor: "#919eab !important",
      color: "#919eab !important",
      fontSize: "1rem",
    },
    "& .MuiInputBase-root input": {
      fontSize: isMobile ? "1rem" : "1.25rem",
      padding: isMobile ? "8.5px 0" : "0.85rem 0",
    },
    "& .MuiIconButton-root:hover": {
      background: "transparent",
    },
    width: "100%",
  };

  const ACTIVE_FRAME_SECONDS = {
    M1: 60,
    M5: 300,
    M15: 900,
  };

  const isNewOptionsTab = operationMode === "OPTION";

  const progressPercentage = Math.min(
    (countValue /
      (isNewOptionsTab ? ACTIVE_FRAME_SECONDS[activeMinute?.value] : 60)) *
      100,
    100
  );

  const progressBar = {
    position: "absolute",
    right: 0,
    top: 0,
    bottom: 0,
    width: `${progressPercentage}%`,
    height: "4px",
    backgroundColor:
      countValue < (isNewOptionsTab ? 7 : 4) ? "#FF5382" : "#00DB97",
    transition: "width 1s linear",
    borderRadius: "16px",
  };

  const progressBarBackground = {
    position: "absolute",
    right: 0,
    top: 0,
    bottom: 0,
    width: "100%", // Sempre 100% para cobrir o fundo todo
    height: "4px",
    backgroundColor: "#121f27", // Cinza claro
    borderRadius: "16px",
  };

  const symbolData = useMemo(() => {
    return SYMBOL_IMAGES[selectedSymbol] || SYMBOL_IMAGES.BTCUSDT;
  }, [selectedSymbol]);

  return (
    <>
      <Box
        pb={1}
        sx={{
          display: "flex",
          justifyContent: isMobile ? "flex-start" : "flex-end",
          flexDirection: "column",
          marginBottom: isMobile ? "0" : "1rem",
          alignItems: "end",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          {!isMobile && (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <img width={32} src={symbolData.image} alt="" />
              <Typography sx={{ color: "#CCC" }} variant="body2">
                {symbolData.symbol}{" "}
              </Typography>
            </Box>
          )}
          <Countdown />
        </Box>
        {!isMobile && (
          <Box sx={{ width: "100%", position: "relative" }}>
            <Box sx={progressBarBackground} />
            <Box sx={progressBar} />
          </Box>
        )}
      </Box>

      {isNewOptionsTab ? (
        <>
          <TextField
            sx={inputStyle}
            value={activeMinute?.label || ""}
            label={t("time")}
            disabled={true}
            size={isMobile ? "small" : "medium"}
            InputProps={{
              startAdornment: (
                <InputAdornmentIcon
                  icon={FaCircleMinus}
                  onClick={decreaseAmount}
                />
              ),
              endAdornment: (
                <InputAdornmentIcon
                  onClick={increaseAmount}
                  position="end"
                  icon={FaCirclePlus}
                />
              ),
              inputProps: {
                style: { WebkitAppearance: "none" },
              },
            }}
          />
          {!isMobile && <Typography height={16.35}></Typography>}
        </>
      ) : (
        <FormControl fullWidth>
          <TimePicker />
        </FormControl>
      )}
    </>
  );
}
